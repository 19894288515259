import request from "graphql-request";
import React, { useEffect, useState } from "react";
import "../assets/css/soft.scss";
import ContactUs from "../components/contactUs";
import Head from "../components/head";
import Layout from "../components/layout";
import Loading from "../components/loading";
import ShowProvider from "../components/showProvider";
import SingleRealizationCard from "../components/singleRealizationCard";
function SoftPage({ pageContext }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [realizations, setRealizations] = useState([]);
  const strapiHost = process.env.GATSBY_API_URL;

  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `  {
          soft(locale: "${pageContext.intl.language}") {
            pageTitle
            description
            title
            img {
              url
            }
            title_realizations
            software_single {
              description 
              title
              img {
                url
              }
            } 
          }
          realizations (limit: 3,locale: "${pageContext.intl.language}",where:{realization_category:{slug:"website"}}) {
            title
            logo{
              url
            }
            thumbnailOptionalLogo{
              url
            }
            slug
           }
        }`
      );
      return res;
    };

    fetch().then((items) => {
      setData(items.soft);
      setRealizations(items.realizations);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ShowProvider>
          <Layout contactUs={false} initial={false} nav={false}>
            <div className="soft__section">
              <div className="soft__left">
                <h1 className="soft__title">{data.title}</h1>
                <p className="soft__description">{data.description}</p>
              </div>
              <div className="soft__right">
                <img
                  src={`${strapiHost}${data.img.url}`}
                  className="soft__img"
                />
              </div>
            </div>
          </Layout>
          <div className="soft__single">
            <Layout nav={false} initial={false} contactUs={false}>
              <div className="soft__single-wrapper">
                {data.software_single.map(({ description, img, title }) => (
                  <div className="soft__single-offer">
                    <div className="soft__single-header">
                      <img
                        src={`${strapiHost}${img.url}`}
                        className="soft__img2"
                      />
                      <h2 className="soft__single-title">{title}</h2>
                    </div>
                    <p className="soft__single-desc1">{description}</p>
                  </div>
                ))}
              </div>
            </Layout>
          </div>
          <Layout initial={false} contactUs={false}>
            <div className="soft__realizations">
              <h2 className="soft__realizations-title">
                {data.title_realizations}
              </h2>
              <div className="soft__realizations-content">
                {realizations.map(
                  ({ logo, slug, title, thumbnailOptionalLogo }, key) => (
                    <SingleRealizationCard
                      key={key}
                      title={title}
                      bg={"rgb(248, 249, 252)"}
                      img={
                        thumbnailOptionalLogo
                          ? thumbnailOptionalLogo.url
                          : logo.url
                      }
                      to={slug}
                    />
                  )
                )}
              </div>
            </div>
          </Layout>
        </ShowProvider>
      )}
      <ContactUs />
    </>
  );
}

export default SoftPage;
